<!--系統部署-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">


    <div>
      <div class="content-header">
        <h1 class="m-0 text-dark">系統部署重要步骤</h1>
      </div>
      <el-row :gutter="20">
        <el-col :xl="4" :lg="6" :md="8" :sm="12" v-for="(item, index) in importantData" :key="index">
          <el-card class="box-card" :class="item.colorFlag == true ? 'turnOnModel' : 'unopenedModel'">
            <div slot="header" class="card-header">
              <h3 class="card-title">{{ item.title }}</h3>
            </div>
            <div class="card-body p-0" @click="OnclickImportant(item)">
              <div class="bodyIconBox VerticalMid">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="item.icon"></use>
                </svg>
                <div class="divCheckBox" :class="item.boxFlag == true ? ' ' : 'hidden '">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-check"></use>
                  </svg>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="content-header">
        <h1 class="m-0 text-dark">系統基础数据</h1>
      </div>
      <el-row :gutter="20">
        <el-col :xl="4" :lg="6" :md="8" :sm="12" v-for="(item, index) in basicData" :key="index">
          <el-card class="box-card" :class="item.colorFlag == true ? 'turnOnModel' : 'unopenedModel'">
            <div slot="header" class="card-header">
              <h3 class="card-title">{{ item.title }}</h3>
            </div>
            <div class="card-body p-0" @click="Onclickbasic(index)">
              <div class="bodyIconBox VerticalMid">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="item.icon"></use>
                </svg>
                <!-- <div class="divCheckBox" :class="item.boxFlag == true ? ' ' : 'hidden '">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-check"></use>
                  </svg>
                </div> -->
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div class="btnCont">
      <div class="btnSumbit" @click="onGetEnable()">
        完成系統部署
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        fullscreenLoading: false,

        //colorFlag为灰色或者为蓝色
        //clickFlag是否能够点击
        //boxFlag对号图标是否出现
        //重要步骤数据
        importantData: [{
            title: "1、添加教师角色",
            icon: "#fax-user-circle-o",
            name: "",
            state: null,
            url: "../role/index",
            colorFlag: true,
            clickFlag: true,
            boxFlag: "",
            code: 'hasRole',
          },
          {
            title: "2、添加综合类型",
            icon: "#fax-file-gear-o",
            name: "",
            state: null,
            url: "../GrowthConfig/Index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasGrowthConfig',
          },
          {
            title: "3、添加学年",
            icon: "#fax-date-clock-o",
            name: "",
            state: null,
            url: "../academic/index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasYear',
          },
          {
            title: "4、添加学期",
            icon: "#fax-clock-o",
            name: "",
            state: null,
            url: "../semester/index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasSemester',
          },
          {
            title: "5、添加教师账号",
            icon: "#fax-user-business",
            name: "",
            state: null,
            url: "../user/index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasUser',
          },
          {
            title: "6、添加年级信息",
            icon: "#fax-layer-group-alt",
            name: "",
            state: null,
            url: "../College/Index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasCollege',
          },

          {
            title: "7、添加班级信息",
            icon: "#fax-cube-users-alt",
            name: "",
            state: null,
            url: "../class/index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasClass',
          },
          {
            title: "8、添加教师的学生范围",
            icon: "#fax-user-crowd-alt",
            name: "",
            state: null,
            url: "../user/index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasUserFile',
          },
          {
            title: "9、添加活动配置",
            icon: "#fax-file-gear-o",
            name: "",
            state: null,
            url: "../ActiveConfig/Index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasActivityType',
          },
          {
            title: "10、导入学生数据",
            icon: "#fax-user",
            name: "",
            state: null,
            url: "../Student/index",
            colorFlag: "",
            clickFlag: "",
            boxFlag: "",
            code: 'hasStudent',
          },
        ],
        //基础数据
        basicData: [{
            title: "处分等级",
            icon: "#fax-file-punish",
            name: "",
            state: null,
            url: "../DataDictionary/Punish/Type",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasPunishLevel',
          },
          {
            title: "个人奖励类型",
            icon: "#fax-file-star",
            name: "",
            state: null,
            url: "../DataDictionary/Prize/Type",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasPrizeType',
          },
          {
            title: "个人奖励级别",
            icon: "#fax-file-star",
            name: "",
            state: null,
            url: "../DataDictionary/Prize/Level",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasPrizeLevel',
          },
          {
            title: "班级奖励类型",
            icon: "#fax-medals-alt",
            name: "",
            state: null,
            url: "../ClassPrizeType/index",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasClassPrizeType',
          },
          {
            title: "班级奖励级别",
            icon: "#fax-medals-alt",
            name: "",
            state: null,
            url: "../ClassPrizelevel/Index",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasClassPrizeLevel',
          },
          {
            title: "组织数据",
            icon: "#fax-cube-users-alt",
            name: "",
            state: null,
            url: "../DataDictionary/Tissue",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasOrganization',
          },
          {
            title: "综合课堂类型",
            icon: "#fax-book-open",
            name: "",
            state: null,
            url: "../LiteracyCourseType/Index",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasLiteracyCourseType',
          },
          {
            title: "课程基础数据",
            icon: "#fax-jiaoyu",
            name: "",
            state: "",
            url: "../Course/Index",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasCourse',
          },
          {
            title: "学生职位数据",
            icon: "#fax-file-user-o",
            name: "",
            state: "",
            url: "../Adviser/ClassPosition/Index",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            code: 'hasClassPosition',
          },
          {
            title: "扣分类型",
            icon: "#fax-file-user-o",
            name: "",
            state: "",
            url: "../MoralityReason/Type",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            // code: 'hasClassPosition',
          },
          {
            title: "扣分原因",
            icon: "#fax-file-user-o",
            name: "",
            state: "",
            url: "../MoralityReason/Index",
            colorFlag: true,
            clickFlag: "",
            boxFlag: "",
            // code: 'hasClassPosition',
          },
        ],
        //长度
        importantnum: "",
        basicnum: "",
        isShosFirstPage: false,
        isShowDeploy: false,
      };
    },
    created() {
      let that = this;
      that.onDetial();



    },
    methods: {
      /**
       * @description 完成系統部署
       */
      onGetEnable() {
        let that = this;
        confirmDelete(that, '是否确认已完成系統的基本部署', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, '/api/admin/sysaction/enable/workbench', null, function(res) {
              that.fullscreenLoading = false;
            });
          }
        });
      },
      /**
       * @description 搜索
       * @param {Object} item
       */
      handleSelect(item) {
        this.selInfo = item;
      },

      /**
       * @description 跳转
       * @param {Object} that
       * @param {Object} objUrl
       */
      onGoPath(that, objUrl) {
        if (!regNull(objUrl)) {
          that.$router.push({
            path: objUrl,
            query: {
              url: that.$router.history.current.fullPath
            }
          });
        }
      },

      /**
       * @description 模块跳转
       */
      onGoModule(objType) {
        this.$router.replace(objType);
      },
      /**
       * @description 加载数据
       */
      onDetial() {
        //调取数据
        let i = 0;
        let that = this;
        let list = [];
        let list2 = [];
        let count = 0;
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/home", null, function(resData) {
          that.fullscreenLoading = false;
          that.isShowDeploy = Object.entries(resData).every(item => item[1] == true);
          // if (!that.isShowDeploy) {
            list = Object.entries(resData);
            list.forEach(function(item, index) {
              list2.push({
                name: item[0],
                state: item[1],
                colorFlag: "",
                clickFlag: "",
              });
              that.newitemData = list2;
              return index;
            });
            for (var it of list2) {
              for (var i in that.importantData) {
                if (that.importantData[i].code == it.name) {
                  that.importantData[i].state = it.state
                }
              }
            }
            let tempOption = that.basicData[4 + 1];
            that.importantnum = that.importantData.length;
            //重要步骤
            that.importantData.forEach(function(item, index) {
              if (index < that.importantnum - 1) {
                if (item.state == true) {
                  item.clickFlag = false;
                  item.boxFlag = true;
                  if (item.colorFlag == true && item.boxFlag == true) {
                    item.clickFlag = false;
                    that.importantData[index + 1].clickFlag = true;
                    that.importantData[index + 1].colorFlag = true;
                  }
                }
              } else if (index == that.importantnum - 1) {
                if (
                  that.importantData[index].state &&
                  that.importantData[index - 1].state
                ) {
                  that.importantData[index].boxFlag = true;
                  that.importantData[index].clickFlag = false;
                }
              }
            });
        });
      },
      OnclickImportant(index) {
        let that = this;
        if (index.colorFlag == true) {
          that.$router.push({
            path: index.url
          });
        }
      },
      Onclickbasic(index) {
        let that = this;
        // if (that.basicData[index].clickFlag == true) {
        that.$router.push({
          path: that.basicData[index].url
        });
        // }
      },
    },
  };
</script>

<style scoped="scoped" lang="less">
  .btnCont {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 50px;

    .btnSumbit {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 1px solid #dcdfe6;
      color: #606266;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      transition: .1s;
      font-weight: 500;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      padding: 14px 40px;
      font-size: 18px;
      border-radius: 50px;

      &:hover {
        background-color: #ecf5ff;
        border-color: #c6e2ff;
        color: #409eff;
      }
    }
  }


  .turnOnModel .bodyIconBox {
    height: 150px;
    width: 100%;
    overflow: hidden;
    font-size: 62px;
    color: #409eff;
    position: relative;
  }

  .turnOnModel .divCheckBox {
    font-size: 28px;
    color: #67c23a;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    right: 10px;
  }

  .unopenedModel .bodyIconBox {
    height: 150px;
    font-size: 62px;
    color: #cecece;
  }

  .unopenedModel .card-title {
    color: #cecece;
  }

  .unopenedModel .divCheckBox {
    display: none;
  }
</style>
