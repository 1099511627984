<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{pageTitle}}
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="班级名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入班级名称" @blur="onChangeName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="别名" prop="alias">
                <el-input v-model="ruleForm.alias" placeholder="请输入别名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入学年份" prop="year">
                <el-select filterable v-model="ruleForm.year" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in 5" :key="item" :label="item | flGrade" :value="item | flGrade">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属年级" prop="collegeId">
                <el-select filterable v-model="ruleForm.collegeId" placeholder="请选择年级" class="width_100Pie">
                  <el-option v-for="item in collegeOpt" :key="item.collegeId" :label="item.name"
                    :value="item.collegeId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班主任" prop="teacher">
                <el-select v-model="ruleForm.teacherId" clearable filterable style="width: 100%;" placeholder="请选择"
                  @change="handleChangeTeacher">
                  <el-option v-for="(item,index) in optTeacher" :key="index" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onVerification('ruleForm')">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fullscreenLoading: false,
        // 页面标题
        pageTitle: '新增班级信息',
        // 集合
        ruleForm: {
          alias: null, //班级名称，类似2301，代表23年入学1班
          name: null, //别名，例如高一（5）班
          year: null, //年份
          collegeId: null,
          teacherId: null, //班主任ID
        },
        // 验证
        rules: {
          alias: [{
              required: true,
              message: '班级名称不能为空',
              trigger: 'blur'
            },
            {
              max: 256,
              message: '班级名称不能超过256个字符',
              trigger: 'blur'
            },
          ],
          name: [{
              required: true,
              message: '别名不能为空',
              trigger: 'blur'
            },
            {
              max: 256,
              message: '别名不能超过256个字符',
              trigger: 'blur'
            },
          ],
          year: [{
            required: true,
            message: '请选择入学年份',
            trigger: 'change'
          }],
          collegeId: [{
            required: true,
            message: '请选择所属年级',
            trigger: 'blur'
          }],
        },
        collegeOpt: [],
        // 重置时的表单数据
        ruleFormReset: null,
        // 年份
        gradeYear: 0,
        // 班主任下拉框
        optTeacher: [],
        // 辅导员下拉框
        optAssistant: [],

        isType: false,
      };
    },
    methods: {
      onChangeName() {
        let that = this;
        if (!regNull(that.ruleForm.name)) {
          ajaxGet(that, "/api/admin/class/autocomplete", {name: that.ruleForm.name}, (r) => {

          })
        }
      },
      // 辅导员下拉框
      handleChangeAssistant(obj) {
        let that = this;
        if (obj != '') {
          that.ruleForm.assistantId = obj;
          that.ruleForm.assistant = that.optAssistant.find(function(item) {
            return item.value === obj;
          }).label;
        } else {
          that.ruleForm.assistantId = null;
        }
      },
      // 班主任下拉框
      handleChangeTeacher(obj) {
        let that = this;
        if (obj != '') {
          that.ruleForm.teacherId = obj;
          that.ruleForm.teacher = that.optTeacher.find(function(item) {
            return item.value === obj;
          }).label;
        } else {
          that.ruleForm.teacherId = null;
        }
      },
      // 所属专业下拉框
      handleChangeMajor(obj) {
        let that = this;
        that.ruleForm.collegeId = obj[1];
      },
      // 返回
      onBack() {
        let that = this;
        back(that);
      },
      // 重置
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      //验证表单
      onVerification(objForm) {
        let that = this;
        // 验证
        that.$refs[objForm].validate((valid) => {
          if (valid) {
            that.onPostData(that);
          } else {
            return false;
          }
        });
      },
      // 提交
      onPostData(that) {
        that.fullscreenLoading = true;
        // API
        let apiUrl = '/api/admin/class';
        if (that.isType) {
          // 编辑数据
          apiUrl = apiUrl + '/' + that.ruleForm.classId;
          // 更新数据
          ajaxPut(that, apiUrl, that.ruleForm, function(res) {
            that.fullscreenLoading = false;
            that.onBack();
          });
        } else {
          // 新增
          ajaxPost(that, apiUrl, that.ruleForm, function(res) {
            that.fullscreenLoading = false;
            that.onBack();
          });
        }
      },
      // 编辑获取班级数据
      onGetClass(that, ClassID) {
        let apiUrl = '/api/admin/class/' + ClassID;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, null, function(obj) {
          that.ruleForm.alias = obj.alias;
          that.ruleForm.name = obj.name;
          that.ruleForm.year = obj.year;
          that.ruleForm.teacherId = obj.teacherId && obj.teacherId.toLowerCase();
          that.ruleForm.collegeId = obj.collegeId;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          that.fullscreenLoading = false;
        });
      },

      // 班主任下拉框数据绑定
      onGetTeacher(that) {
        that.fullscreenLoading = true;
        that.optTeacher = [];
        ajaxGet(that, '/api/admin/sysuser', null, function(resUser) {
          that.fullscreenLoading = false;
          if (!regNullArray(resUser)) {
            resUser.forEach((item) => {
              if(item.enable==true){
                that.optTeacher.push({
                  value: item.userId,
                  label: '【' + item.userName + '】' + item.name
                });
              }
            });
          }
        })
      },
      // 辅导员下拉框数据绑定
      onGetAssistant(that) {
        // that.optAssistant = [{
        //   value: '0',
        //   label: '测试辅导员'
        // }];
        let apiUrl = '/api/admin/sysuser';
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, null, function(obj) {
          that.fullscreenLoading = false;
          for (var item of obj) {
            var it = {
              value: item.userId,
              label: item.name
            }
            that.optAssistant.push(it);
          }
        })
      }






    },

    created() {
      let that = this;
      // 班级ID
      let classID = that.$route.params.id;
      var apiUrl = null;
      that.isType = false;
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));



      //判断是否有传值过来
      if (classID != null && typeof(classID) != "undefined" && classID != "") {
        that.pageTitle = "编辑班级信息";
        that.ruleForm.classId = classID;
        that.isType = true;
        that.onGetClass(that, classID);

      }

      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/college', null, function(obj) {
        that.collegeOpt = obj
        // onCascaderData(that, obj);
        that.fullscreenLoading = false;
      // 加载班主任数据
      that.onGetTeacher(that);
      // 加载辅导员
      that.onGetAssistant(that);
      });

    },
    mounted() {

    },
    filters: {
      flGrade(obj) {
        let gradeYear = new Date().getFullYear() + 1;
        return gradeYear - obj;
      },
    }

  };
</script>

<style>
  .cascaderMajor .el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }

  .cascaderMajor .el-input__inner:-moz-placeholder {
    color: #606266;
  }

  .cascaderMajor .el-input__inner::-moz-placeholder {
    color: #606266;
  }

  .cascaderMajor .el-input__inner:-ms-input-placeholder {
    color: #606266;
  }
</style>
