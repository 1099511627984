<template>
  <div></div>
</template>

<script>
  export default {
    created() {
      let that = this;
      let tpID = that.$route.query.Id;
      if (!regNull(tpID)) {
        window.location.href = that.$WebUrl() + "/mobile/#/pages/Teacher/DailyBehavior/Index?qrresult=" + tpID;
        // window.location.href = "http://localhost:8085/mobile/#/pages/Teacher/DailyBehavior/Index?qrresult=" + tpID;
      } else {
        window.location.href = that.$WebUrl() + "/mobile/#/pages/Login";
        // window.location.href = "http://localhost:8085/mobile/#/";
      }
    }
  };
</script>

<style>
</style>
