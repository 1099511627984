<template>
  <div></div>
</template>

<script>
  export default {
    created() {
      let that = this;
      let tpID = that.$route.query.Id;
      if (!regNull(tpID)) {
        window.location.href = that.$WebUrl() + "/mobile/#/pages/Teacher/DailyBehavior/Index?classId=" + tpID;
      } else {
        window.location.href = that.$WebUrl() + "/mobile/#/pages/Login";
      }
    }
  };
</script>

<style>
</style>
