<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{ Title }}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="活动名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入活动名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="优先级" prop="priority">
                <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="所属类型" prop="parentId">
               <el-cascader style="width: 100% !important;"
                :options="selectData"  @change="onChange()"
                :props="{expandTrigger: 'hover',label:'name',value:'typeId',children:'children' }"
                clearable v-model="ruleForm.parentIds"></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--综合类型-->
      <el-card style="margin-top: 10px;" id="LiteracyType">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            综合类型
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-tabs v-model="activeName" tab-position="left" type="border-card" @tab-click="handleTabChange">
                <template>
                  <el-tab-pane v-for="item in ruleForm.activityrole" :key="item.roleId" :name="item.name">
                    <span slot="label">
                      <el-checkbox v-model="item.checked" @change="handleCheckChange"></el-checkbox>
                      {{item.name}}
                    </span>
                    
                    <div class="divTreeBox">
                      <el-tree :data="item.treeList" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="configId"
                        @node-click="handleNodeClick" :default-expand-all="true" :show-checkbox="comType"
                        :filter-node-method="filterNode" @check-change="handleCheckChangeConfig"
                        :default-checked-keys="item.defCheckedKeys">
                        <div class="divTreeItemBox" slot-scope="{ node, data }">
                          <span v-if="regNull(data.parentId)" class="spLabelBox">{{ node.label }}</span>
                          <span v-else class="spChildName">{{ node.label }}</span>
                          <span v-if="regNullArray(data.children)">
                            <el-input size="mini" type="number" v-model="data.score" class="w-50 txtScore"
                              v-if="node.checked && comType" @change="onChangeScore(data)" placeholder="请输入分值">
                            </el-input>
                            <span class="spScoreBox" :class="data.score < 0?'spScore_Small':'spScore_Big'"
                              v-else-if="data.score != null && data.score != '' && data.score != 0">分值：{{data.score}}</span>
                          </span>
                        </div>
                      </el-tree>
                    </div>
                  </el-tab-pane>
                </template>
              </el-tabs>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--班级评价类型-->
      <!-- <el-card style="margin-top: 10px;" id="LiteracyType">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            班级评价类型
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-alert title="综合分计算公式：综合分 = 分数 * 综合系数，综合系数最多保留小数点后一位。" type="warning" :closable="false">
          </el-alert>
          <div class="divTreeBox">
            <el-tree :data="ruleForm.treeList2" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="configId"
              @node-click="handleNodeClick" :default-expand-all="true" :show-checkbox="comType"
              :filter-node-method="filterNode" @check-change="handleCheckChangeConfig"
              :default-checked-keys="defCheckedKeys">
              <div class="divTreeItemBox" slot-scope="{ node, data }">
                <span v-if="regNull(data.parentId)" class="spLabelBox">{{ node.label }}</span>
                <span v-else class="spChildName">{{ node.label }}</span>
                <span v-if="regNullArray(data.children)">
                  <el-input size="mini" type="number" v-model="data.score" class="w-50 txtScore"
                    v-if="node.checked && comType" @change="onChangeScore(data)" placeholder="请输入系数">
                  </el-input>
                  <span class="spScoreBox" :class="data.score < 0?'spScore_Small':'spScore_Big'"
                    v-else-if="data.score != null && data.score != '' && data.score != 0">系数：{{data.score}}</span>
                </span>
              </div>
            </el-tree>
          </div>
        </div>
      </el-card> -->

      <!-- 活动配置 -->
      <el-card class="box-card" style="margin-top: 16px;">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            活动配置
          </h3>
        </div>
        <div class="card-body">
          <template>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="ruleForm.activityitems">
              <el-checkbox v-for="p in activityitem" :label="p.Value" :key="p.Value" style="margin-bottom: 15px;"
                :disabled="p.disabled">{{p.Text}}</el-checkbox>
            </el-checkbox-group>
          </template>
        </div>
      </el-card>
    </el-form>

    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        comType: true,
        data: [],
        ruleForm: { //表单数据
          typeId: null,
          name: null,
          priority: null,
          parentId: null,
          treeList: [],
          treeList2: null,
          activityitems: [],
          checked: false,
          activityrole: [],
          configs: [],
          parentIds:[],
          fieldConfigs: [],
        },
        // 树型
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        // 默认值
        defCheckedKeys: [],
        defaultConfigs: [],
        activityroleReset: null,
        ruleFormReset: null, //重置时的表单数据
        Title: "新增活动类型",
        fullscreenLoading: false,
        rules: {
          name: [{
              required: true,
              message: '活动名称不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '活动名称不能超过20个字符',
              trigger: 'change'
            },
          ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },

        activityitem: [],
        selectData: [], //所属类型集合
        activeName: "",
        currentId: null,
        currentTitle: null,
      };
    },
    computed: {
      getScoreVerify() {
        let rules = [{
            required: true,
            message: '分值必须填写',
            trigger: 'change'
          },
          {
            pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
            message: '请输入数字',
            trigger: 'change'
          }
        ]
        return rules
      },
      getTableProp() {
        return function(index, index2) {
          return `treeList[${index}].children[${index2}].score`
        }
      },
      getTableProp2() {
        return function(index, index2) {
          return `treeList2[${index}].children[${index2}].score`
        }
      }
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      if (!regNull(that.$route.params.id)) { //编辑
        that.fullscreenLoading = true;
        //获取活动字段
        ajaxGet(that, "/api/admin/activitytype/type", null, function(resType) {
          that.fullscreenLoading = false;
          if (!regNullArray(resType)) {
            that.activityitem = [];
            that.ruleForm.activityitems = [];
            resType.forEach((item) => {
              if (item.Text.indexOf('审核人') != -1 || item.Text.indexOf('活动名称') != -1 || item.Text.indexOf('活动地点') != -1 || item.Text
                .indexOf('活动类型') != -1) {
                that.ruleForm.activityitems.push(item.Value);
                that.activityitem.push({
                  Value: item.Value,
                  Text: item.Text,
                  disabled: true
                });
              } else {
                that.activityitem.push({
                  Value: item.Value,
                  Text: item.Text,
                  disabled: false
                });
              }
            });
            that.onEdit(id);
          }
        });
      } else { //新增
        //获取活动字段
        that.fullscreenLoading = true;
        that.selectData = [];
        ajaxGet(that, "/api/admin/activitytype", null, function(r) {
          that.fullscreenLoading = false;
          var tree = {
            typeId: null,
            value: null,
            name: "活动配置",
            title: "活动配置",
            children:  [],
          };
          for(var it of r){
            if(it.parentId == null){
              tree.children.push({
                typeId: it.typeId,
                name: it.name,
                parentId : it.parentId,
              })
            }
          }
          var newTree = JSON.parse(JSON.stringify(tree));

          that.selectData.push(newTree);
        });
        ajaxGet(that, "/api/admin/activitytype/type", null, function(resType) {
          that.fullscreenLoading = false;
          that.activityitem = [];
          that.ruleForm.activityitems = [];
          if (!regNullArray(resType)) {
            resType.forEach((item) => {
              if (item.Text.indexOf('审核人') != -1 || item.Text.indexOf('活动名称') != -1 || item.Text.indexOf('活动地点') != -1 || item.Text.indexOf('活动类型') != -1) {
                that.ruleForm.activityitems.push(item.Value);
                that.activityitem.push({
                  Value: item.Value,
                  Text: item.Text,
                  disabled: true
                });
              } else {
                if (item.Text.indexOf('时间') != -1) {
                  that.ruleForm.activityitems.push(item.Value);
                }
                that.activityitem.push({
                  Value: item.Value,
                  Text: item.Text,
                  disabled: false
                });
              }
            });
          }
        });
        that.onAdd();
      }


    },
    methods: {
      onChange(){
        let that = this;
        that.ruleForm.parentId = that.ruleForm.parentIds[that.ruleForm.parentIds.length-1];
      },
      onAdd() { //新增
        let that = this;
        that.isDetail = false;

        //获取活动角色-综合类型
        ajaxGet(that, "/api/admin/GrowthConfig", null, function(r2) {
          for (var it of r2) {
            it.isSelect = false;
            it.score = 0;
          }
          ajaxGet(that, "/api/admin/activityrole", null, function(r1) {
            let index = 0;
            for (var it of r1) {
              it.checked = false;
              it.configs = [];
              it.configsClass = [];
              it.defCheckedKeys = [];

              let configs = JSON.parse(JSON.stringify(r2));
              configs.forEach(element => {
                if (element.configType == 1) {
                  element.roleId = it.roleId;
                  it.configs.push(element);
                }
              })
              
              it.treeList = onGetJsonToTree(it.configs.map(item => ({
                configId: item.configId,
                name: item.name,
                priority: item.priority,
                parentId: item.parentId,
                description: item.description,
                ceiling: item.ceiling,
                configType: item.configType,
                baseScore: item.baseScore,
                applys: item.applys,
                score: item.score,
                roleId: item.roleId,
                checked: regNull(item.checked) ? false : item.checked,
                disabled: index == 0 ? false : true,
              })), 'configId', 'parentId', 'children'); //将列表变成tree treeData(that.configList);

              let configsClass = JSON.parse(JSON.stringify(r2));
              configsClass.forEach(element => {
                if (element.configType == 2) {
                  element.roleId = it.roleId;
                  it.configsClass.push(element);
                }
              })
              index = index + 1;
            }

            that.ruleForm.treeList2 = onGetJsonToTree(r1[0].configsClass.map(item => ({
              configId: item.configId,
              name: item.name,
              priority: item.priority,
              parentId: item.parentId,
              description: item.description,
              ceiling: item.ceiling,
              configType: item.configType,
              baseScore: item.baseScore,
              applys: item.applys,
              score: item.score,
              roleId: item.roleId,
              checked: regNull(item.checked) ? false : item.checked,
            })), 'configId', 'parentId', 'children'); //将列表变成tree treeData(that.configList);

            that.ruleForm.activityrole = r1;
            that.ruleForm.activityrole[0].checked = true;
            that.activeName = r1[0].name;

            that.activityroleReset = JSON.parse(JSON.stringify(that.ruleForm.activityrole));
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          })
        })

        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onEdit(id) { //编辑
        let that = this;
        that.Title = "编辑活动类型";
        that.isDetail = false;
        that.data = [];
        that.selectData = [];
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/activitytype", null, function(r) {
          that.fullscreenLoading = false;
          /*用于新增、编辑时的选择，下拉框只能选择第一级*/
          var tree = {
            typeId: null,
            value: null,
            name: "活动配置",
            title: "活动配置",
            children:[],
          };
          for(var it of r){
            if(it.parentId == null){
              tree.children.push({
                typeId: it.typeId,
                name: it.name,
                parentId : it.parentId,
              })
            }
          }
          var newTree = JSON.parse(JSON.stringify(tree));

          that.selectData.push(newTree);

          let editUrl = "/api/admin/activitytype/" + id;
          ajaxGet(that, editUrl, null, function(resActType) {
            that.ruleForm.typeId = resActType.typeId;
            that.ruleForm.name = resActType.name;
            that.ruleForm.priority = resActType.priority;
            that.ruleForm.parentId = resActType.parentId;
            that.ruleForm.parentIds =[null,resActType.parentId];
            that.ruleForm.configs = resActType.configs;
            //获取活动字段
            ajaxGet(that, "/api/admin/activitytype/type", null, function(resType) {
              if (!regNullArray(resType) && !regNullArray(resActType.fieldConfigs)) {
                resType.forEach((item) => {
                  resActType.fieldConfigs.forEach((list) => {
                    if (list.name == item.Value) {
                      that.ruleForm.activityitems.push(item.Value);
                    }
                  });
                });
              }
            });

            //获取活动角色-综合类型
            that.activeName = "";
            ajaxGet(that, "/api/admin/GrowthConfig", null, function(r2) {
              for (var it1 of r2) {
                it1.isSelect = false;
                it1.score = 0;
              }
              ajaxGet(that, "/api/admin/activityrole", null, function(r1) {
                for (var it of r1) {
                  it.checked = false;
                  it.configs = [];
                  it.configsClass = [];
                  it.defCheckedKeys = [];

                  let configs = JSON.parse(JSON.stringify(r2));
                  configs.forEach(element => {
                    if (element.configType == 1) {
                      element.roleId = it.roleId;
                      it.configs.push(element);
                    }
                  })

                  resActType.configs.forEach(ele => {
                    if (ele.roleId == it.roleId) {
                      var config = it.configs.find(val => { return val.configId == ele.configId });
                      if (!regNull(config)) {
                        config.checked = true;
                        config.score = ele.score;
                        it.checked = true;
                        it.defCheckedKeys.push(ele.configId);
                        

                        if (regNull(that.activeName)) {
                          that.activeName = it.name;
                        }
                      }
                    }
                  })

                  it.treeList = onGetJsonToTree(it.configs.map(item => ({
                    configId: item.configId,
                    name: item.name,
                    priority: item.priority,
                    parentId: item.parentId,
                    description: item.description,
                    ceiling: item.ceiling,
                    configType: item.configType,
                    baseScore: item.baseScore,
                    applys: item.applys,
                    score: item.score,
                    roleId: item.roleId,
                    checked: regNull(item.checked) ? false : item.checked,
                    disabled: it.checked ? false : true,
                  })), 'configId', 'parentId', 'children'); //将列表变成tree treeData(that.configList);

                  let configsClass = JSON.parse(JSON.stringify(r2));
                  configsClass.forEach(element => {
                    if (element.configType == 2) {
                      element.roleId = it.roleId;
                      var config = resActType.configs.find(x => x.roleId == it.roleId && x.configId == element.configId);
                      if (!regNull(config)) {
                        element.checked = true;
                        element.score = config.score;
                        that.defCheckedKeys.push(config.configId);
                      }

                      it.configsClass.push(element);
                    }
                  })
                }

                that.ruleForm.treeList2 = onGetJsonToTree(r1[0].configsClass.map(item => ({
                  configId: item.configId,
                  name: item.name,
                  priority: item.priority,
                  parentId: item.parentId,
                  description: item.description,
                  ceiling: item.ceiling,
                  configType: item.configType,
                  baseScore: item.baseScore,
                  applys: item.applys,
                  score: item.score,
                  roleId: item.roleId,
                  checked: regNull(item.checked) ? false : item.checked,
                })), 'configId', 'parentId', 'children'); //将列表变成tree treeData(that.configList);

                that.ruleForm.activityrole = r1;
                
                if (regNull(that.activeName)) { 
                  that.activeName = r1[0].name;
                }

                that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
                that.activityroleReset = JSON.parse(JSON.stringify(that.ruleForm.activityrole));
              })
            });
          });
        });
      },
      handleCheckChange(val) {
        if (this.ruleForm.activityrole.length > 0) {
          let it = this.ruleForm.activityrole.find(it => it.name == this.activeName);
          it.defaultConfigs = [];
          it.defCheckedKeys = [];
          it.treeList = onGetJsonToTree(it.configs.map(item => ({
            configId: item.configId,
            name: item.name,
            priority: item.priority,
            parentId: item.parentId,
            description: item.description,
            ceiling: item.ceiling,
            configType: item.configType,
            baseScore: item.baseScore,
            applys: item.applys,
            score: null,
            roleId: item.roleId,
            checked: val,
            disabled: !val,
          })), 'configId', 'parentId', 'children'); //将列表变成tree treeData(that.configList);
          
        }
      },
      handleTabChange() {
        
      },
      onReset() { //重置
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        that.ruleForm.activityrole = JSON.parse(JSON.stringify(that.activityroleReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      getValue(value) {
        let that = this;
        that.ruleForm.parentId = value;
      },
      onSave() { //保存（新增和编辑）
        let that = this;
        if (that.ruleForm.priority == null || that.ruleForm.priority == "") {
          that.ruleForm.priority = "0"
        }

        let fieldConfigs = [];
        for (var i in that.ruleForm.activityitems) {
          var fieldConfig = {
            name: parseInt(that.ruleForm.activityitems[i]),
          };
          fieldConfigs.push(fieldConfig);
        }
        that.ruleForm.fieldConfigs = fieldConfigs;

        if (!regNullArray(that.ruleForm.configs)) {
          that.$refs["ruleForm"].validate(valid => {
            if (valid) {
              if (that.ruleForm.typeId == null) {
                that.fullscreenLoading = true;
                ajaxPost(that, "/api/admin/activitytype", that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.onBack()
                });
              } else {
                that.fullscreenLoading = true;
                ajaxPut(that, "/api/admin/activitytype/" + that.ruleForm.typeId, that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.onBack()
                });
              }
            } else {
              setTimeout(() => {
                var isError = document.getElementsByClassName("is-error");
                isError[0].querySelector('input').focus();
              }, 1)
              return false;
            }
          });
        } else {
          warning(that, "请确认综合类型的配置");
        }

      },
      onBack() {
        let that = this;
        back(that);
      },
      handleCheckChangeConfig(data, checked) {
        let that = this;
        if (!checked) {
          data.score = null;
          that.onChangeScore(null, data.configId)
        }
      },
      /**
       * @description 树型点击事件
       * @param {Object} objItem
       */
      handleNodeClick(objItem) {
        let that = this;
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      /**
       * @description 输入分值后传值至父页
       */
      onChangeScore(objItem, objValID) {
        let that = this;
        
        if (!regNull(objItem)) {
          if (regNull(that.ruleForm.configs)) {
            that.ruleForm.configs.push(objItem);
          } else {
            let tpInfo = that.ruleForm.configs.find(item => item.configId == objItem.configId);
            if (regNull(tpInfo)) {
              that.ruleForm.configs.push(objItem);
            } else {
              tpInfo.score = objItem.score;
            }
          }
        } else if (!regNull(objValID)) {
          that.ruleForm.configs = that.ruleForm.configs.filter(item => item.configId != objValID);
        }
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }
</style>

<style lang="less">
  .divTreeBox {
    .el-tree-node__content {
      height: inherit;
    }

    label {
      margin-bottom: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
</style>

<style scoped="scoped" lang="less">
  .divTreeBox {
    overflow: auto;
    min-width: 100px
  }

  .divTreeItemBox {
    .spLabelBox {
      background-color: #ecf5ff;
      color: #409eff;
      border: 1px solid #d9ecff;
      border-radius: 4px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .spChildName {
      height: 35px;
      display: inline-block;
      line-height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 120px;
    }

    .spScoreBox {

      border-radius: 4px;
      height: 30px;
      display: inline-block;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }

    .spScore_0 {
      background-color: #909399;
      color: #FFFFFF;
      border: 1px solid #909399;
    }

    .spScore_Big {
      background-color: #F56C69;
      color: #FFFFFF;
      border: 1px solid f56c6c;
    }

    .spScore_Small {
      background-color: #67c23a;
      color: #FFFFFF;
      border: 1px solid #67c23a;
    }
  }
</style>
