<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{pageTitle}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="学年" prop="year">
                <el-select v-model="ruleForm.year" style="width: 100%;" placeholder="请选择学年" @change="handleChangeYear">
                  <el-option v-for="(item,index) in optYear" :key="index" :label="item.name" :value="item.yearId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学期名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入学期名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学期日期范围" prop="arrDate">
                <el-date-picker style="width: 100%;" v-model="ruleForm.arrDate" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>

              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="假期日期范围" prop="arrHoliday">
                <el-date-picker style="width: 100%;" v-model="ruleForm.arrHoliday" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" :rows="5" placeholder="请输入" v-model="ruleForm.remark" maxlength="300"
                  show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onVerification('ruleForm')">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fullscreenLoading: false,
        // 页面标题
        pageTitle: '新增学期信息',
        // 集合
        ruleForm: {
          semesterId: '', //ID
          year: '', //学年
          yearId: '', //学年ID
          name: '', //名称
          startDate: '', //学期开始时间
          endDate: '', //学期结束时间
          startHoliday: '', //假期开始时间
          endHoliday: '', //假期结束时间
          remark: '', //备注
          arrDate: [],
          arrHoliday: []
        },
        // 验证
        rules: {
          name: [{
              required: true,
              message: '学期名称不能为空',
              trigger: 'blur'
            },
            {
              max: 256,
              message: '学期名称长度在256个字符内',
              trigger: 'blur'
            },
          ],
          year: [{
            required: true,
            message: '请选择学年',
            trigger: 'change'
          }],
          arrDate: [{
            type: 'array',
            required: true,
            message: '请选择日期区间',
            fields: {
              //tpye类型试情况而定,所以如果返回的是date就改成date
              0: {
                type: 'date',
                required: true,
                message: '请选择开始日期'
              },
              1: {
                type: 'date',
                required: true,
                message: '请选择结束日期'
              },
            }
          }, ],
          arrHoliday: [{
            type: 'array',
            required: true,
            message: '请选择日期区间',
            fields: {
              //tpye类型试情况而定,所以如果返回的是date就改成date
              0: {
                type: 'date',
                required: true,
                message: '请选择开始日期'
              },
              1: {
                type: 'date',
                required: true,
                message: '请选择结束日期'
              },
            }
          }, ],
        },
        // 重置时的表单数据
        ruleFormReset: null,
        // 学年
        optYear: [],
        // false:新增 | true:编辑
        isType: false,
      };
    },
    methods: {
      // 下拉选择后绑值
      handleChangeYear(obj) {
        let _this = this;
        _this.ruleForm.yearId = obj;
        let temp=_this.optYear.find(item=>item.yearId==_this.ruleForm.yearId)
        _this.ruleForm.name=temp.name
      },
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },
      // 重置
      onReset() {
        let _this = this;
        _this.ruleForm = JSON.parse(JSON.stringify(_this.ruleFormReset));
        try {
          _this.$refs["ruleForm"].resetFields();
          _this.onResetForm('ruleForm');
        } catch {}
        _this.ruleForm.arrDate = [new Date(_this.ruleForm.startDate), new Date(_this.ruleForm.endDate)];
        _this.ruleForm.arrHoliday = [new Date(_this.ruleForm.startHoliday), new Date(_this.ruleForm.endHoliday)];

      },
      // 关闭验证
      onResetForm(objForm) {
        let _this = this;
        _this.$refs[objForm].resetFields();
      },
      //验证表单
      onVerification(objForm) {
        let _this = this;
        // 验证
        _this.$refs[objForm].validate((valid) => {
          if (valid) {
            _this.onPostData(_this);
          } else {

            return false;
          }
        });
      },
      // 提交
      onPostData(_this) {
        _this.fullscreenLoading = true;

        // API
        let apiUrl = '/api/admin/semester';
        if (_this.isType) {
          // 编辑数据
          _this.ruleForm.startDate = dateFormat(_this.ruleForm.arrDate[0]);
          _this.ruleForm.endDate = dateFormat(_this.ruleForm.arrDate[1]);
          _this.ruleForm.startHoliday = dateFormat(_this.ruleForm.arrHoliday[0]);
          _this.ruleForm.endHoliday = dateFormat(_this.ruleForm.arrHoliday[1]);
          apiUrl = apiUrl + '/' + _this.ruleForm.semesterId;
          // 更新数据
          ajaxPut(_this, apiUrl, _this.ruleForm, function(res) {
            _this.fullscreenLoading = false;
            _this.onBack();
          });
        } else {
          // 新增
          _this.ruleForm.startDate = dateFormat(_this.ruleForm.arrDate[0]);
          _this.ruleForm.endDate = dateFormat(_this.ruleForm.arrDate[1]);
          _this.ruleForm.startHoliday = dateFormat(_this.ruleForm.arrHoliday[0]);
          _this.ruleForm.endHoliday = dateFormat(_this.ruleForm.arrHoliday[1]);
          ajaxPost(_this, apiUrl, _this.ruleForm, function(res) {
            _this.fullscreenLoading = false;
            _this.onBack();
          });
        }
      },
      // 获取学年
      onGetYear(_this) {
        let apiUrl = '/api/admin/academic'
        _this.fullscreenLoading = true;
        ajaxGet(_this, apiUrl, null, function(obj) {
          _this.optYear = obj;
          _this.fullscreenLoading = false;
        });
      },
      // 获取编辑数据
      onGetEditData(_this) {
        let apiUrl = '/api/admin/semester/' + _this.ruleForm.semesterId;
        _this.fullscreenLoading = true;
        ajaxGet(_this, apiUrl, null, function(obj) {
          _this.ruleForm.name = obj.name;
          _this.ruleForm.year = obj.year;
          _this.ruleForm.yearId = obj.yearId;
          _this.ruleForm.startDate = dateFormat(obj.startDate);
          _this.ruleForm.endDate = dateFormat(obj.endDate);
          _this.ruleForm.startHoliday = dateFormat(obj.startHoliday);
          _this.ruleForm.endHoliday = dateFormat(obj.endHoliday);
          //转换时间格式
          _this.ruleForm.arrDate = [new Date(_this.ruleForm.startDate), new Date(_this.ruleForm.endDate)];
          _this.ruleForm.arrHoliday = [new Date(_this.ruleForm.startHoliday), new Date(_this.ruleForm.endHoliday)];
          _this.ruleForm.remark = obj.remark;
          _this.ruleFormReset = JSON.parse(JSON.stringify(_this.ruleForm));
          _this.fullscreenLoading = false;

        })

      }


    },
    created() {
      let _this = this;
      // 班级ID
      let dataID = _this.$route.params.id;
      var apiUrl = null;
      _this.isType = false;
      _this.ruleFormReset = JSON.parse(JSON.stringify(_this.ruleForm));

      //判断是否有传值过来
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        _this.pageTitle = "编辑学期信息";
        _this.ruleForm.semesterId = dataID;
        _this.isType = true;
        _this.onGetEditData(_this);
      }

      _this.onGetYear(_this);

    },
    mounted() {

    },
    filters: {

    }

  };
</script>

<style>

</style>
