<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="card" v-if="ruleForm!=null">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          综合参数详情
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="20">
          <el-col :lg="8" :md="8" :sm="12" class="colInfo">
            <label>参数名称</label>
            <div class="colFonts">{{ruleForm.name | nullData}}</div>
          </el-col>
          <el-col :lg="8" :md="8" :sm="12" class="colInfo">
            <label>上限分</label>
            <div class="colFonts">{{ruleForm.ceiling | nullData}}</div>
          </el-col>
          <el-col :lg="8" :md="8" :sm="12" class="colInfo">
            <label>基础分</label>
            <div class="colFonts">{{ruleForm.baseScore | nullData}}</div>
          </el-col>
          <!-- <el-col :lg="6" :md="8" :sm="12" class="colInfo">
            <label>是否操行评定分</label>
            <div class="colFonts">{{ruleForm.isConductEvaluation?'是':'否'}}</div>
          </el-col> -->
          <el-col :lg="24" class="colInfo">
            <label>描述</label>
            <div class="colFonts" v-if="ruleForm.description!=null && ruleForm.description.length>0"
              v-html="ruleForm.description">
            </div>
            <div class="colFonts" v-else>暂无数据</div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <div v-if="ruleForm.applys!=null && ruleForm.applys.length>0">
      <el-card class="card" v-for="(item,index) in ruleForm.applys" :key="index">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{item.typeName}}
          </h3>
        </div>
        <div class="card-body">
          <el-table :data="item.items" border class="elTableData">
            <el-table-column prop="name" :label="item.typeName+'名称'"></el-table-column>
            <el-table-column prop="role" :label="item.typeName.indexOf('综合积分')>=0?'分数':'角色'"
              v-if="onIsShow(item.items)"></el-table-column>
            <el-table-column prop="score" :label="item.typeName.indexOf('综合积分')>=0?'系数':'分数'" width="120">
            </el-table-column>
          </el-table>
        </div>
        <div class="card-footer" v-if="item.remark!=null">
          备注：{{item.remark}}
        </div>
      </el-card>
    </div>



    <el-card class="card" v-if="ruleForm==null">
      <div class="card-body">
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row " style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fullscreenLoading: false,
        ruleForm: {
          configId: '', //ID
        },

      };
    },
    methods: {
      /**
       * @description 定义role字段的显示文字
       */
      onRoleName(objItem) {

      },
      /**
       * @description 通过数据判断是否显示此列
       * @param {Object} objItem
       */
      onIsShow(objItem) {
        let iCount = false;
        if (!regNullArray(objItem)) {
          iCount = objItem.every(item => regNull(item.role) == false);
        }
        return iCount;
      },
      // 返回
      onBack() {
        let that = this;
        back(that);
      },
    },
    created() {
      let that = this;
      //判断是否有传值过来
      if (!regNull(that.$route.params.Id)) {
        ajaxGet(that, '/api/admin/growthconfig/' + that.$route.params.Id, null, function(resData) {
          if (!regNull(resData)) {
            that.ruleForm = resData;
          } else {
            that.ruleForm = null;
          }
        });
      } else {
        that.ruleForm = null;
      }
    }
  }
</script>

<style>
</style>
