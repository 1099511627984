<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="card">
        <div slot="header" class="card-header">
        	<h3 class="card-title titleCont">
        	  <span class="spIcon"></span>
        	  班级详情
        	</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>班级名称</label>
              <div class="colFonts">{{ruleForm.name | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>别名</label>
              <div class="colFonts">{{ruleForm.alias | flNoValue}}</div>
            </div>

            <div class="col-lg-3 colInfo">
              <label>入学年份</label>
              <div class="colFonts">{{ruleForm.year | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>所属年级</label>
              <div class="colFonts">{{ruleForm.college | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班主任</label>
              <div class="colFonts">{{ruleForm.teacher | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <div class="row " style="margin-top: 10px;">
      		<div class="col-12">
      			<el-button  icon="el-icon-back" @click="onBack()">返回</el-button>
      		</div>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        fullscreenLoading:false,
        ruleForm: {
          classId: '', //ID
          name: '', //名称
          alias: '',
          college:'',
          year: '', //年份
          teacher: '',
        },
      };
    },
    methods:{
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },
    },
    filters:{
      flNoValue(obj){
        if (obj == null || typeof(obj) == "undefined" || obj == ''){
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let _this = this;
      // 班级ID
      let classID = _this.$route.params.id;

      //判断是否有传值过来
      if (classID != null && typeof(classID) != "undefined" && classID != "") {
        _this.ruleForm.classId = classID;
        var apiUrl = '/api/admin/class/' + classID;
        _this.fullscreenLoading = true;
        ajaxGet(_this, apiUrl, null, function(obj) {
          _this.ruleForm.alias = obj.alias;
          _this.ruleForm.name = obj.name;
          _this.ruleForm.year = obj.year;
          _this.ruleForm.teacher = obj.teacher;
          _this.ruleForm.college = obj.college;
          _this.fullscreenLoading = false;
        });
      }else{
        _this.back();
      }
    }
  }
</script>

<style>
</style>
